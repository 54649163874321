.parent-article {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: whitesmoke;
}

.notification-parent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.notification-box {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.notification-data {
  display: flex;
  gap: 10px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.notification-title {
  font-weight: bold;
  margin: 0;
}

.notification-time {
  margin: 0;
  color: gray;
}

.dots-icon {
  display: flex;
  align-items: center;
}
