@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.img {
  width: 100%;
  /* height: 80vh !important; */
}
.heading {
  font-size: 28px;
  color: #424a82;
  font-family: "Poppins" sans-serif !important ;
  text-align: left;
  font-weight: 500;
}
.subHeading {
  font-size: 38px;
  font-family: "Poppins";
  font-weight: bold;
  text-align: left;
  width: 400px;
}
.content {
  font-size: 18px;
  font-family: "Poppins";
  text-align: left;
  color: #141414;
  width: 100%;
  text-align: justify;
  /* margin-bottom: 40px; */
}
.green {
  font-weight: 600 !important;
}
.btn {
  color: white;
  width: 150px;
  height: 50px;
  font-size: 14px;
  background-color: #4e2d69 !important;
}
.btn:hover {
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.text {
  margin-left: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .text {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .heading {
    font-size: 25px;
  }
  .subHeading {
    font-size: 28px;
    width: 300px;
  }
  .btn {
    height: 50px;
    width: 200px;
  }
}
@media screen and (max-width: 950px) {
  .imgDiv {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .imgDiv {
    margin-top: 0px;
  }
  .heading {
    font-size: 20px;
  }
  .subHeading {
    font-size: 22px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 500px) {
  .heading {
    font-size: 18px;
  }
  .subHeading {
    font-size: 20px;
    margin-bottom: 20px;
    width: 70%;
  }
  .content {
    width: 95%;

    margin-bottom: 23px;
  }
}

@media screen and (max-width: 355px) {
  .subHeading {
    width: 100%;
  }
}
