@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.heading {
  font-family: "Poppins" !important;
  font-size: 38px;
  padding-top: 55px;
  box-shadow: 0 5px 4px -6px;
  color: #333333;
  text-align: start;
  font-weight: bold;
}

.sub_text {
  font-family: "Poppins" !important ;
  font-size: 20px;
  color: #222a41;
  margin-top: 23px;
  text-align: start;

  margin-bottom: 30px;
}
.logo_head {
  font-family: "Poppins" !important ;
  font-weight: bold;
  color: #000000;
  margin-top: 14px;
  text-align: start;
}
.logo_text {
  font-family: "Poppins" !important ;
  font-size: 14px;
  color: #222a41;

  text-align: start;

  margin-right: 40px;
}
.sub {
  font-family: "Poppins" sans-serif !important ;
  margin-top: 50px;
  padding-bottom: 55px;
}

.profile {
  height: 30px;
}
.profile_text {
  padding-left: 10px;
}
.profile_text h6 {
  margin-bottom: 0;
}
.profile_text p {
  font-size: 13px;
  color: #6d6d6d;
}
.follow {
  margin-left: 1rem;
  text-align: start;
}
.follow_head {
  font-family: "Poppins" !important ;
  font-size: 13px;
  font-weight: bold;
  color: #222a41;
  text-align: start;
}
.follow_text {
  font-family: "Poppins" !important ;
  font-size: 11px;
  color: #222a41;
  text-align: start;
  margin-right: 40px;
  padding-bottom: 0.5rem;
}
.follow_btn {
  text-align: start;
  color: black;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 1rem;
  border: 1px solid rgb(0, 0, 0);
}
.comment_section {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding: 2rem;
}
.comment_section input {
  height: 5rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.sharing {
  padding: 1rem;
  border-top: 1px solid rgb(125, 125, 125);
  border-bottom: 1px solid rgb(125, 125, 125);
}
.sharing svg {
  font-size: 1.5rem;
}
.sharing p {
  margin-right: 1rem;
}
.sharing a {
  color: black;
}
@media screen and (max-width: 1000px) {
  .heading {
    font-size: 35px;
    padding-top: 100px;
  }
  .sub {
    padding-bottom: 100px;
    margin-top: 80px;
  }
  .sub_text {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 770px) {
  .heading {
    font-size: 25px;
    padding-top: 70px;
    width: 400px;
  }
  .sub_text {
    font-size: 17px;
  }
  .sub {
    padding-bottom: 70px;
    margin-top: 60px;
  }
  .logo_head {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 0px;
  }
  .logo_text {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 430px) {
  .heading {
    font-size: 18px;
    padding-top: 50px;
    width: 100%;
  }
  .sub_text {
    font-size: 13px;
    margin-top: 5px;
  }
  .logo_head {
    font-size: 15px;
  }
  .sub {
    padding-bottom: 13px;
  }
}
