/* Product.css */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

a {
  text-decoration: none;
  color: white;
}
/* Main container */

/* Image container */
.product-image {
  text-align: center; /* Center the image */
}

.product-image img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Pet details container */
.pet-details {
  background-color: #fff; /* White background */
  padding: 20px; /* Add padding */
  border-radius: 8px; /* Add border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.pet-details h1 {
  font-size: 24px; /* Title font size */
  margin-bottom: 15px; /* Add spacing below the title */
}

.pet-details h6 {
  font-size: 16px; /* Details font size */
  margin-bottom: 10px; /* Add spacing below each detail */
}

/* Description container */
.product-description {
  background-color: #eff4f4; /* Light gray background */
  border-radius: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.description-title {
  font-size: 20px; /* Title font size */
  margin-bottom: 15px; /* Add spacing below the title */
}

.description-text {
  font-size: 16px; /* Description font size */
  line-height: 1.6; /* Increase line height for readability */
}
.img-detail {
  transform: translate(-130%, 30%);
}
.bg-image {
  filter: blur(2px) !important;
  padding: 1rem;
}

/* Sty
le.css */
.comment-section {
  margin-top: 20px;
}

.comment-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.comment-list {
  margin-bottom: 20px;
}

.comment {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.comment-input {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
}

.comment-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.comment-submit:hover {
  background-color: #0056b3;
}

.abouttext {
  background-color: white;
  margin-top: 0;
  z-index: 999;
}

.vector {
  height: 300px;
  max-width: 100%;
}

.herotext {
  text-align: start;
  background-color: #d8f6f7;
  margin-top: 2rem;
  border-radius: 1rem;
  font-family: "Raleway", sans-serif;
}
.herotext h6 {
  font-weight: 500;
  font-size: 45px;
}
.herotext p {
  color: #8b8b8b !important;
  font-weight: 300;
}
.btn-hero {
  background-color: rgb(28, 26, 26) !important;
}

.swiper img {
  filter: brightness(70%);
}
@media (max-width: 767px) {
  .owl-carousel-item img {
    height: 600px !important;
    max-width: 800px;
    overflow: hidden !important;
    object-fit: contains;
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 2/1;
    object-fit: cover;
    object-position: top center;
  }
  .herotext {
    justify-content: center !important;
    text-align: center;
  }
}
.loginbtn {
  border-radius: 2rem !important;
  font-weight: 800;
  margin-left: 1rem;
  max-width: 100%;
}
.loginbtn a {
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .right-nav {
    margin-left: 1.3rem;
  }
}

.grey {
  background-color: #eeeff0;
  border-radius: 2rem;
}
.Featured {
  background-color: #eeeff0;
  padding-top: 4rem;
  padding-bottom: 4rem;
  font-family: "Poppins" !important ;
}
.pet-card {
  border-radius: 2rem;
  background-color: white;
  font-family: "Poppins" !important ;
}
.pet-image {
  border-radius: 1.8rem;
  height: 200px;
}

.btn-purple {
  background-color: #000000 !important;
}
.btn-outline-more {
  color: black;
  border: 1px solid #000000;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-more {
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  border: none;
}
.btn-more:hover {
  cursor: pointer !important;
  -webkit-transition-duration: 0.4s !important; /* Safari */
  transition-duration: 0.4s !important;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.card-text {
  font-size: 14px;
  font-weight: 400;
  color: #929292;
}
.all-pets {
  padding-top: 3rem;
  align-items: center;
  justify-content: center;
  font-family: "Poppins" !important ;
}

.filter {
  background-color: white;
  color: #296e70;
  margin-top: 3rem;
  font-family: "Poppins" !important ;
  border-radius: 2rem;
  padding: 2rem;
  text-align: start;
}
.Details {
  font-family: "Poppins" !important ;
}
.detail-card {
  text-align: center;
}

.Menucard {
  background: linear-gradient(to top, #4e2d69, #656ebb) !important;
  padding-bottom: 1px;
  border-radius: 2rem;
}

.Menucard img {
  width: auto;
  height: 50vh;
}

@media screen and (max-width: 500px) {
  .Menucard img {
    height: 30vh;
  }
}
.username {
  max-width: 100px; /* Adjust the maximum width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-input {
  display: block;
  width: 10rem;
  padding: 0.375rem 2rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dropdown-menu {
  bs-dropdown-min-width: none !important;
  --bs-dropdown-padding-x: none !important;
  --bs-dropdown-padding-y: 0.5rem;
}

.dropdown-item:hover {
  color: white;
  background-color: #4e2d69;
  border-radius: 0.8rem;
}
@media screen and (max-width: 767px) {
  .col-sms-10 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .hero-btn {
    flex-direction: column;
    line-height: 3rem;
  }
  .col-sms-9 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .col-sms-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}
@media screen and (max-width: 380px) {
  .col-sms-5 {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
@media screen and (max-width: 991px) {
  .filter {
    padding: 1rem;
  }
}

.card {
  border-radius: 2rem !important;
}

.card img {
  border-radius: 2rem;
}

.card-body p {
  font-size: 14px;
}
.dashboard {
  font-family: "Poppins" !important ;
}
.list-group-item.active {
  z-index: 2;
  background-color: #4e2d69 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #4e2d69 !important;
  border-radius: 2rem;
}
@media screen and (max-width: 576px) {
  .bg-img {
    height: 190px;
  }
}
@media screen and (max-width: 991px) {
  .article-nav {
    display: none !important;
  }
}
.usersandpost {
  background-color: #8b8b8b;
}
.usersandpost svg {
  font-size: 70px;
}

.article-parent {
  /* background-color: red; */
  /* padding: 0px 20px; */
  height: 310px;
}

.blog-image-parent {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.blog-images {
  border: 2px solid transparent;
  padding: 18px;
  background-color: #e2e2e2;
  height: 200px;
  width: 100%;
  max-width: 353px;
  /* object-fit: cover; */
}

.nav-item {
  cursor: pointer;
}

@media (max-width: 768px) {
  .article-parent {
    flex-direction: column;
    /* max-height: 500px; */
    height: 100%;
  }

  .blog-image-parent {
    order: 1;
  }

  .blog-images {
    width: 100%;
    /* height: 50%; */
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
  }
}
