.profile-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  background-color: #f8f8f8;
}

.profile-sidebar {
  flex: 0 0 300px;

  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -70px;
}

.profile-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stat {
  display: flex;
  justify-content: space-between;
}

.edit-profile-btn {
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  border: none;
  font-size: large;
  background-color: black;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.form-container {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-section {
  display: flex;
  gap: 20px;
}

.input-field {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.save-button {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  border: none;
  font-size: large;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.profile-content {
  flex: 1;
  margin-left: 20px;
}

.divider {
  border-bottom: 2px solid black;
}

.articles {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

@media only screen and (max-width: 800px) {
  .articles {
    display: grid;
    grid-template-columns: auto;
  }
}

.article {
  flex: 1 1 calc(50% - 20px);
  background-color: white;
  /* padding: 4px; */
  /* border-radius: 2px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.image-outside {
  /* background-color: #f2e2e2; */
  /* border-radius: 10px; */
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.image-outside img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article img {
  height: 300px;
  object-fit: cover;
  background-color: #e2e2e2;
  /* margin: 0px 20px; */
  padding: 20px 20px;
}

.article-content {
  padding: 10px;
}

.article-content p {
  color: gray;
  text-align: start;
}

/* for the user form details */
.form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
}

h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

h3 {
  font-size: 1em;
  margin: 15px 0 10px;
}

.form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
}

h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

h3 {
  font-size: 1em;
  margin: 15px 0 10px;
}

.form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
}

h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

h3 {
  font-size: 1em;
  margin: 15px 0 10px;
}

.form-section {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add gap between input fields */
}

.form-section input {
  width: 48%;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px; /* Add margin-bottom for gap */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.save-button {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px 0 0;
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background: #333;
}
