@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Reem+Kufi+Fun&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@200&family=Jost:ital,wght@0,200;0,300;0,400;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* background-image: url("../src/imag/pet1.jpg");  */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensures the background image stays fixed as the content scrolls */
}
p {
  margin-bottom: 0!;
}

.dark-font {
  color: #000000 !important;
  font-size: 17px;
  font-weight: 300;
}
.navbar {
  font-family: "Raleway", sans-serif;

  font-size: 17px;
  line-height: 26px;
  background-color: white;
  /* text-transform: uppercase; */
  border-top-left-radius: 0; /* Set border radius for top left corner to 0 */
  border-top-right-radius: 0;

  box-shadow: 0 5px 4px -6px;
  z-index: 99;
}

.navbar-brand {
  font-family: "roboto", sans-serif;
  letter-spacing: normal;
}
.real {
  padding-top: 1rem;
  background-color: #f9f9f9;
}

/* =============================
==========footer============= */
.footer {
  color: white;
  padding: 25px;
  background-color: #072849 !important;
  /* top-left, top-right, bottom-right, bottom-left */
}
.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}
.footer a:hover {
  color: rgb(165, 169, 139);
}

/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========================================= */
/* =========Register ========= */
.greenlogo {
  height: 70px !important;
}
.register {
  align-items: center;
  justify-content: center;
  height: none !important;
  margin-top: 5rem;
  margin-bottom: 5rem;

  border-radius: 2rem;
}

.logoname {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logoname img {
  height: 100px;
}
/* ========================================= */
/* =========User Info ========= */
/* UserInfo.css */

.userInfo-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.userInfo-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.userInfo-info {
  margin-bottom: 10px;
}

.userInfo-label {
  font-weight: bold;
}

.userInfo-value {
  margin-left: 10px;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.card-image {
  height: auto !important;
}

.filter-bar {
  background-color: #ffffff;
}
li {
  text-decoration: none !important;
}
.nav-item {
  font-weight: 100 !important;
}

.wallpaper-image-2 {
  z-index: -1;
}
.wallpaper-image img {
  width: 100%;
  height: 550px;
}
/* @media only screen and (max-width: 963px) {
  .wallpaper-image img {
    height: auto;
  }
} */
.orange-box {
  height: 50%;
  width: 6%;
  background-color: orange;
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translate(10%, -50%);
}
/* @media only screen and (max-width: 972px) {
  .orange-box {
    left: 5%;
  }
} */
.wallpaper-text {
  color: white;
  font-size: 40px;
  position: absolute;

  font-weight: 700;
  transform: translate(10%, -50%);
  z-index: 3;
}
/* @media only screen and (max-width: 972px) {
  .wallpaper-text {
    left: 10%;
  }
}
@media only screen and (max-width: 720px) {
  .wallpaper-text {
    font-size: 30px;
  }
} */
.wallpaper-image-2 img {
  width: 100%;
  height: 350px;
  margin-top: -20px;
  filter: brightness(70%);
}

.wallpaper-text-2 {
  color: white;
  text-align: center;
}
@media only screen and (max-width: 1595px) {
  .wallpaper-image-2 img {
    height: auto;
  }
}
.wallpaper-text-2 p {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}
@media only screen and (max-width: 1595px) {
  .wallpaper-2 {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1595px) {
  .wallpaper-text-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.card-home {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.wallpaper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .right-img {
    margin-top: 2rem !important;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (max-width: 768px) {
  .right-img {
    margin-top: 3rem !important;
  }
}
.register h1 {
  font-family: "Nunito Sans", sans-serif !important;
}

/* ========================================= */
/* =========Comment-Reply ========= */
.comment-list {
  margin: 20px 0; /* Add some space above and below the comment list */
}

.comment {
  padding: 10px; /* Add padding for a clear visual structure */
  border-bottom: 1px solid #ddd; /* Separate comments with a border */
}

.reply-list {
  padding-left: 20px; /* Indent replies for visual distinction */
  border-left: 2px solid #ddd; /* Add a vertical line to separate replies */
  margin-top: 10px; /* Space between the comment and its replies */
}

.reply {
  padding: 5px 10px; /* Add padding to replies */
  background-color: #f9f9f9; /* Use a light background for replies */
}

.reply-button {
  margin-left: 10px; /* Space between the comment and the reply button */
  color: #007bff; /* Blue text for the reply button */
  background: none;
  border: none;
  cursor: pointer;
}

.reply-input {
  width: 80%; /* Control the width of the input box */
  margin-top: 10px; /* Space above the input box */
}

.reply-submit {
  margin-top: 10px; /* Space between the input box and the submit button */
  color: white;
  background-color: #007bff; /* Blue background for the submit button */
  border: none;
  padding: 5px 10px; /* Add padding to the submit button */
  cursor: pointer;
}

/* ========================================= */
/* =========Adoption-Pending ========= */
.adoption-table {
  width: 95%;
  border-collapse: collapse;
}

.adoption-table th,
.adoption-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.adoption-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.adoption-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.adoption-table tbody tr:hover {
  background-color: #ddd;
}

/* ========================================= */
/* =========Product-Details (delete/edit button) ========= */
.comment {
  margin-bottom: 20px;
}

.comment-author,
.reply-author {
  font-weight: bold;
}

.comment-actions,
.reply-actions {
  margin-top: 5px;
}

.comment-actions button,
.reply-actions button {
  margin-right: 5px;
}

.comment-text,
.reply-text {
  margin-top: 5px;
  padding: 5px;
  background-color: #f2f2f2;
}

.reply {
  margin-left: 20px;
}

.reply-input {
  margin-bottom: 5px;
  width: 100%;
}

.reply-submit {
  margin-left: 5px;
}

.delete-comment,
.delete-reply,
.edit-comment,
.edit-reply {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-comment:hover,
.delete-reply:hover,
.edit-comment:hover,
.edit-reply:hover {
  background-color: #d6342d;
}
.input {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 6px;
  border: solid 1px #e8e8e8;
  height: 50px;
  margin: 9px 0px 20px;
  padding-left: 2rem;
}
.login-icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(60%, 85%);
}

.link {
  color: black;
}

.sidebar {
  padding: 0.8rem;
  border-radius: 3rem;
  background-color: #d9d9d9;
}
.sidebar img {
  height: 50px;
}

.sidebar p {
  margin-top: 10px;
}

.follow-sec {
  border-radius: 9px;
  border: 1px solid rgb(149, 149, 149);
}
.profile-card {
  border: 1px solid rgb(149, 149, 149);
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  top: -20%;
  transform: translate(0%, -15%);
}
.profile-name {
  transform: translate(0%, -35%);
}
.profile-name img {
  max-width: 100%;
  height: 100px;
  border-radius: 50%;
}
.details {
  transform: translate(0%, -25%);
}
.box {
  border-top: 1px solid rgb(125, 125, 125); /* Top border */
  border-bottom: 1px solid rgb(125, 125, 125); /* Bottom border */
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 16px;
  color: #333;
}
